import { SortDirection } from '@angular/material/sort';
import { UIColumnDef } from '@bannerflow/ui';

export interface Language {
    id: string;
    flag: string;
    versionName: string;
    targetUrl: string;
    mainLocalization: boolean;
}

interface Path {
    id: string;
    name: string;
}
export interface CreativeSetTableData {
    items: CreativeSetListItem[];
    totalItemsCount: number;
    path: Path[];
}

export interface Size {
    height: number;
    width: number;
}
// To be refactor to be creativeset or folder
export interface CreativeSetListItem {
    id: string;
    externalId?: string;
    name: string;
    creativesCount: number;
    createdAt: string;
    createdBy: string;
    modifiedAt: string;
    modifiedBy: string;
    status: string;
    locations?: Location[];
    type: ListItemType;
    thumb?: string;
}

export interface Location {
    id: string;
    name: string;
}
export interface CreatedFilter {
    from: Date;
    till: Date;
    by: string[];
}

export enum ListItemType {
    Folder = 'Folder',
    CreativeSet = 'CreativeSet'
}

export interface ActiveColumnDef extends UIColumnDef {
    active: boolean;
}
export interface Sorting {
    fieldName: string;
    order: SortDirection;
}
export interface Filters {
    createdBy?: string[];
    languages?: string[];
    date?: DateRange;
    sizes?: string[];
}

export interface FiltersRequest {
    created?: CreatedFilter;
    localizationIds?: string[];
    sizes?: Size[];
    name?: string;
}
export interface DateRange {
    startDate: Date;
    endDate: Date;
}

export enum CreativeSetsDuplicationStatus {
    Pending,
    Completed,
    InternalError,
    OperationCompleted
}

export interface RequestParams {
    page: number;
    pageSize: number;
    sort: Sorting;
    folderId?: string;
    filters?: Filters;
}

export enum CreativeSetDeletionStatus {
    Completed,
    ConnectedToCampaign,
    NotFound,
    InternalError
}

export interface CreativeSetDeletionResult {
    creativeSetId: string;
    name: string;
    folderName: string;
    modifiedAt: string;
    status: CreativeSetDeletionStatus;
    statusText?: string;
}

export interface CreateCreativeSetRequest {
    name: string;
    folderId?: string;
    targetUrl: string;
    versionName: string;
    localizationId: string;
}

export interface CreativeSetDuplicationResult {
    progress: { status: CreativeSetsDuplicationStatus }[];
    parentFolderId: string;
    targetBrandId: string;
}
