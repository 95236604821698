import { HttpClient } from '@angular/common/http';
import { Injectable, ViewContainerRef } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { AppService } from '@core/services/internal/app.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { HistoryService } from '@core/services/internal/history.service';
import { SessionService } from '@core/services/internal/session.service';
import { Constants } from '@shared/constants';
import { Account } from '@shared/models/account.model';
import { AdType } from '@shared/models/banner/adType.model';
import { Banner } from '@shared/models/banner/banner.model';
import { BannerFormat } from '@shared/models/banner/bannerFormat.model';
import {
    BANNER_BUTTON_APPROVE,
    BANNER_BUTTON_BANNERTAG,
    BANNER_BUTTON_COMMENT,
    BannerGroup,
    BannerGroupList
} from '@shared/models/banner/bannerGroup.model';
import { BannerSet } from '@shared/models/banner/bannerSet.model';
import { SizeFormat } from '@shared/models/banner/sizeFormat.model';
import { Brand } from '@shared/models/brand.model';
import { Comment } from '@shared/models/comment.model';
import { Owner } from '@shared/models/owner.model';
import { PreviewSettings, PreviewSettingsShow } from '@shared/models/previewSettings.model';
import { User } from '@shared/models/user.model';
import { Culture } from '@shared/models/version/culture.model';
import { Localization } from '@shared/models/version/localization.model';
import { Version } from '@shared/models/version/version.model';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PreviewService {
    private _data: any;
    private previewSlug: string;

    public settings: PreviewSettings;
    public rootViewContainer: ViewContainerRef;

    public bannerSet: BannerSet;
    public originalTranslationId: string;

    public bannerGroupList: BannerGroupList;
    public hideComments: boolean;

    constructor(
        private sessionService: SessionService,
        private appService: AppService,
        private historyService: HistoryService,
        private httpClient: HttpClient
    ) {}

    public notFound(): boolean {
        return !this._data;
    }

    public async init(previewSlug: string, queryString: any = {}): Promise<any> {
        // Hide sidebar in main layout
        this.appService.settings.sidebar = false;

        this.settings = PreviewSettings.fromQueryString(queryString);
        this.previewSlug = previewSlug;

        const url: string = this.settings.commentsSecret
            ? `/api/v1/s/publicpreview?id=${previewSlug}&c=${this.settings.commentsSecret}`
            : `/api/v1/s/publicpreview?id=${previewSlug}`;

        const data: any = await firstValueFrom(this.httpClient.get(`${AppConfig.config.B2_URL}${url}`));

        try {
            this._data = data;

            if (data.originalTranslation) {
                this.originalTranslationId = data.originalTranslation.id;
            }

            this.bannerSet = this.getBannerSet();

            this.hideComments = data.hideComments;
            //  Special initialization of sessionService for public preview
            this.sessionService.user = this.getUser();

            //  TODO: This fix is for header navigation, do better
            if (!this.sessionService.user.anonymous) {
                this.historyService.setPreviousView({
                    title: this.bannerSet.name,
                    url: `/${this.sessionService.user.account.slug}/${this.sessionService.user.brand.slug}/BannerSets/c/${this.bannerSet.slug}`
                });
            }
        } catch (e) {
            console.error(e);
        }

        return this._data;
    }

    private getUser(): User {
        let user: User;

        if (this._data.user) {
            user = new User().deserialize(this._data.user);
        } else {
            user = new User();
            user.anonymous = true;
            user.email = localStorage.getItem('email') || null;
        }

        user.account = this.getAccount();
        user.brand = this.getBrand();

        return user;
    }

    private getAccount(): Account {
        const account: Account = new Account();
        account.slug = this._data.accountSlug;
        account.features = this._data.features;

        return account;
    }

    private getBrand(): Brand {
        const brand: Brand = new Brand();

        brand.name = this._data.brandName;
        brand.logo = this._data.brandLogo;
        brand.slug = this._data.brandSlug;

        return brand;
    }

    public getBannerSet(): BannerSet {
        const bannerSet: BannerSet = new BannerSet();

        if (this._data) {
            bannerSet.id = this._data.id;
            bannerSet.slug = this._data.slug;
            bannerSet.name = this._data.name;
            bannerSet.slug = this._data.slug;
            bannerSet.isDone = this._data.isDone;
            bannerSet.publicId = this._data.publicId;
            bannerSet.comments = this._data.comments.map((comment: any) => {
                return new Comment().deserialize(this.commentMapper(comment));
            });

            bannerSet.totalComments = bannerSet.comments.filter((comment: Comment) => {
                return !comment.approved;
            }).length;

            bannerSet.created = new Date(this._data.created);
            bannerSet.modified = new Date(this._data.modified);
            bannerSet.createdBy = new Owner().deserialize(this._data.embeddedOwner);
        } else {
            bannerSet.publicId = this.previewSlug;
        }

        return bannerSet;
    }

    private formatMapper(format: any): BannerGroup {
        const sizeFormat: SizeFormat = new SizeFormat();
        sizeFormat.deserialize(format.sizeFormat);

        const bannerFormat: BannerFormat = new BannerFormat();
        bannerFormat.id = format.id;
        bannerFormat.name = format.name;
        bannerFormat.sizeFormat = sizeFormat;
        bannerFormat.fileSize = format.fileSize;
        bannerFormat.fileImageSize = format.fileImageSize;
        bannerFormat.duration = format.duration;
        bannerFormat.loops = format.loops;

        const adType: AdType = new AdType();

        if (format.adType) {
            bannerFormat.adType = adType.deserialize(format.adType);
        } else {
            bannerFormat.adType = adType;
            bannerFormat.adType.isRegularAd = true;
        }

        if (this._data.originalTranslation.feedId && this._data.originalTranslation.feedFields.length) {
            bannerFormat.isFeeded = true;
        }

        const banners: Banner[] = format.ads.map((newBanner: any) => {
            return this.generateBanner(newBanner, bannerFormat);
        });

        const bannerGroup: BannerGroup = new BannerGroup();
        bannerGroup.bannerFormat = bannerFormat;
        bannerGroup.banners = banners;

        return bannerGroup;
    }

    private generateBanner(newBanner: any, bannerFormat: BannerFormat): Banner {
        const creative: Banner = new Banner();
        creative.id = newBanner.id;
        creative.bannerFormat = bannerFormat;
        creative.previewBanner = newBanner.previewBanner;
        creative.previewImage = newBanner.previewImage;
        creative.previewScript = newBanner.previewScript;
        creative.placementScript = newBanner.placementScript;
        creative.targetUrl = newBanner.targetUrl;
        creative.landingPageUrl = newBanner.landingPageUrl;

        creative.approved = new Comment().deserialize(newBanner.approved);

        // Set version
        const localization: Localization = new Localization();
        localization.culture = new Culture().deserialize(newBanner.languages[0].culture);
        localization.name = newBanner.languages[0].name;

        creative.version = new Version();
        creative.version.id = newBanner.languages[0].id;
        creative.version.localization = localization;
        creative.version.name = newBanner.languages[0].name;
        creative.version.targetUrl = newBanner.languages[0].targetUrl;
        creative.version.landingPageUrl = newBanner.languages[0].landingPageUrl;

        creative.fileSize = newBanner.fileSize;
        creative.imageFileSize = newBanner.imageFileSize;

        // Set comments
        newBanner.comments.map((comment: any) => this.commentMapper(comment));

        creative.comments = newBanner.comments.map((comment: any) => new Comment().deserialize(comment));

        return creative;
    }

    public getBannerGroupList(): BannerGroupList {
        // Filter formats based on preview settings
        this._data.regularFormats = this.filterBasedOnSettings(this._data.regularFormats);
        this._data.advancedFormats = this.filterBasedOnSettings(this._data.advancedFormats);

        const bannerButtons: string[] = [BANNER_BUTTON_BANNERTAG];
        if (!this.hideComments) {
            bannerButtons.push(BANNER_BUTTON_COMMENT);
            bannerButtons.push(BANNER_BUTTON_APPROVE);
        }

        // If show only one banner
        let bannerGroups: BannerGroup[];
        if (this.settings.bannerId) {
            let format: any = this._data.regularFormats.find((regularFormat: any) => {
                const ad: any = regularFormat.ads.find((ad: any) => ad.id === this.settings.bannerId);

                return !!ad;
            });

            if (!format) {
                format = this._data.advancedFormats.find((advancedFormat: any) => {
                    const ad: any = advancedFormat.ads.find((ad: any) => ad.id === this.settings.bannerId);

                    return !!ad;
                });
            }

            bannerGroups = [this.formatMapper(format)];
        } else {
            // If show all banners
            const regularFormats: BannerGroup[] = this._data.regularFormats.map((format: any) =>
                this.formatMapper(format)
            );
            const advancedFormats: BannerGroup[] = this._data.advancedFormats.map((format: any) =>
                this.formatMapper(format)
            );

            // Sort the regular formats by width
            regularFormats.sort((groupA: BannerGroup, groupB: BannerGroup) => {
                const sizeA: number = groupA.bannerFormat.sizeFormat.size.width;
                const sizeB: number = groupB.bannerFormat.sizeFormat.size.width;

                return sizeB > sizeA ? -1 : sizeA > sizeB ? 1 : 0;
            });

            bannerGroups = regularFormats.concat(advancedFormats);
        }

        return new BannerGroupList(bannerGroups, bannerButtons);
    }

    private filterBasedOnSettings(formats: any): BannerGroup[] {
        // Filter formats based on preview filters
        if (this.settings.sizeFormats.length > 0) {
            formats = formats.filter((format: any) => {
                return this.settings.sizeFormats.find((sizeFormat: string) => {
                    return sizeFormat === format.sizeFormat.id || sizeFormat === format.id;
                });
            });
        }

        // Filter banners based on preview filters
        formats.forEach((format: any) => {
            format.ads = format.ads.filter((ad: any) => {
                // Version filter is set & version is not found in banner
                if (
                    this.settings.versions.length &&
                    !this.settings.versions.find((version: any) => version === ad.languages[0].id)
                ) {
                    return false;
                }

                // If 'only show approved' filter is set and banner is not approved
                if (
                    this.settings.show === PreviewSettingsShow.OnlyApproved &&
                    (ad.approved === null || ad.approved.approved === false)
                ) {
                    return false;
                }
                // If 'only show non approved' filter is set and banner is approved
                else if (
                    this.settings.show === PreviewSettingsShow.OnlyNonApproved &&
                    ad.approved &&
                    ad.approved.approved === true
                ) {
                    return false;
                }

                return true;
            });
        });

        // Remove all formats with no banners
        formats = formats.filter((format: any) => format.ads.length);

        return formats;
    }

    private commentMapper(comment: any): void {
        comment.sizeFormat = {
            size: comment.size
        };

        comment.user = {
            id: comment.userId,
            firstName: this.getFirstName(comment.name),
            lastName: this.getLastName(comment.name),
            email: comment.email,
            profile: comment.profile
        };

        comment.children.forEach((child: any) => {
            child.user = {
                id: child.userId,
                firstName: this.getFirstName(child.name),
                lastName: this.getLastName(child.name),
                email: child.email,
                profile: child.profile
            };
        });

        return comment;
    }

    private getFirstName(name: string): string {
        return name ? name.split(' ')[0] : '';
    }

    private getLastName(name: string): string {
        const arr: string[] = name.split(' ');

        return arr.length > 1 ? arr[1] : '';
    }

    public unlock(password: string, previewSlug: string): Promise<void> {
        const url = `${AppConfig.config.B2_URL}/${Constants.URLS.STATIC}/showcase/authentication/${previewSlug}`;
        const data = {
            password: password
        };

        return this.httpClient
            .post(url, data)
            .toPromise()
            .then((response: any) => {
                // Set authed cookie
                CookieService.set(previewSlug, 'authed');
            });
    }

    public getBannerHtml(banner: Banner): Observable<string> {
        return this.httpClient.get(banner.previewBanner.toString(), {
            responseType: 'text'
        });
    }
}
